<template>
  <div id="app">
    <div class="background">
       <div class="blur">
        <div class="parent">
          <img class="logo" alt="Growth Nexus logo" src="./assets/logo.svg">
          <div class="content">
            <a href="mailto:michael@growthnexus.io">contact</a>
          </div>       
        </div> 
       </div>
    </div>       
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body{
margin:0;
}

.background {
  background-image: url("./assets/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 300%;
  height: 100vh;
  width: 100%;
  animation-name: pulse;
  animation-duration: 60s;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  50%  {background-size: 400%;}
}

.parent{
 position: relative;
 height: 100%;

}

a { color: inherit; text-decoration: none;
} 

.blur {
  background: rgba(1, 11, 76, 0.5); 
  backdrop-filter: blur(8px); 
  height: 100vh;

}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;

}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.content {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);

}
</style>
